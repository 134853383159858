import React from "react";
import Seo from "../components/seo";
import * as styles from "./index.module.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import type { PageProps } from "gatsby";
import cx from "classnames";
import { BlogListItem } from "../components/blog-list";

type Location = Pick<PageProps, "location">["location"];

type VisualSpec = "default" | "h-centered" | "v-centered" | "no-padding";

interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  location: Location;
  seoInfo?: React.ComponentProps<typeof Seo>;
  visualSpec?: VisualSpec | VisualSpec[];
  headerVisualSpec?: React.ComponentProps<typeof Header>["visualSpec"];
  headerThemeSpec?: React.ComponentProps<typeof Header>["themeSpec"];
  mainClassName?: string;
  blogPostInfo?: React.ComponentProps<typeof BlogListItem>;
}
const Layout = React.forwardRef(
  (
    {
      location,
      title,
      children,
      seoInfo,
      visualSpec = "default",
      headerVisualSpec = "default-page",
      headerThemeSpec = "dark",
      mainClassName,
      className,
      blogPostInfo,
      ...divProps
    }: React.PropsWithChildren<LayoutProps>,
    ref: React.ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const rootPath = `${__PATH_PREFIX__}/`;
    const isRootPath = location.pathname === rootPath;

    const validatedVisualSpec = Array.isArray(visualSpec)
      ? visualSpec
      : [visualSpec];

    return (
      <div
        {...divProps}
        ref={ref}
        className={cx(styles.container, className)}
        data-is-root-path={isRootPath}
      >
        {seoInfo && <Seo {...seoInfo} />}
        <div
          className={cx(styles.headerAndMain, {
            [styles.vCentered]: validatedVisualSpec.includes("v-centered"),
          })}
        >
          <Header
            title={title}
            location={location}
            visualSpec={headerVisualSpec}
            themeSpec={headerThemeSpec}
            blogPostInfo={blogPostInfo}
          />
          <main
            className={cx(styles.main, mainClassName, {
              [styles.hCentered]: validatedVisualSpec.includes("h-centered"),
              [styles.noPadding]: validatedVisualSpec.includes("no-padding"),
            })}
          >
            {children}
          </main>
        </div>
        <Footer
          visualSpec={
            validatedVisualSpec.includes("no-padding")
              ? "no-padding"
              : undefined
          }
        />
      </div>
    );
  }
);
Layout.displayName = "Layout";

export default Layout;
