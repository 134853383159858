import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileLines } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface FooterProps {
  visualSpec?: "default" | "no-padding";
}
const Footer = ({ visualSpec = "default" }: FooterProps): JSX.Element => {
  const {
    site: {
      siteMetadata: {
        author: { name: authorName },
        socials: { linkedin: linkedInHandle, github: githubHandle, email },
      },
    },
    allFile,
  } = useStaticQuery(footerQuery);

  let resumeURL;
  if (Array.isArray(allFile?.edges)) {
    const {
      node: { publicURL: pdfPublicURL },
    } = allFile.edges[0];
    resumeURL = pdfPublicURL;
  }

  return (
    <footer
      className={cx(styles.footer, {
        [styles.noPadding]: visualSpec === "no-padding",
      })}
    >
      <span className={styles.copyright}>
        © {authorName}{" "}
        <span className={styles.year}>{new Date().getFullYear()}</span>
      </span>
      <div className={styles.socials}>
        {resumeURL && (
          <OutboundLink
            className={styles.socialLink}
            href={resumeURL}
            target="_blank"
            rel="noopener noreferrer"
            title="Resume"
          >
            <FontAwesomeIcon icon={faFileLines} size="2x" fixedWidth />
          </OutboundLink>
        )}
        {linkedInHandle && (
          <OutboundLink
            className={styles.socialLink}
            href={`https://linkedin.com/in/${linkedInHandle}`}
            target="_blank"
            rel="noopener noreferrer"
            title="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" fixedWidth />
          </OutboundLink>
        )}
        {githubHandle && (
          <OutboundLink
            className={styles.socialLink}
            href={`https://github.com/${githubHandle}`}
            target="_blank"
            rel="noopener noreferrer"
            title="GitHub"
          >
            <FontAwesomeIcon icon={faGithub} size="2x" fixedWidth />
          </OutboundLink>
        )}
        {email && (
          <OutboundLink
            className={styles.socialLink}
            href={`mailto:${email}&subject=Hi!"`}
            target="_blank"
            rel="noopener noreferrer"
            title="Email"
          >
            <FontAwesomeIcon icon={faEnvelope} size="2x" fixedWidth />
          </OutboundLink>
        )}
      </div>
    </footer>
  );
};

export default Footer;

const footerQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        author {
          name
        }
        socials {
          linkedin
          github
          email
        }
      }
    }
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;
