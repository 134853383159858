import React from "react";
import { Link } from "gatsby";
import * as styles from "./index.module.scss";
import cx from "classnames";
import type { PageProps } from "gatsby";
import useAnimationContext from "../../contexts/animation-context";
import { navigate } from "@reach/router";
import { AnimationDirection, getExitVariant } from "../../utilities/animation";
import { BlogListItem } from "../blog-list";

type Location = Pick<PageProps, "location">["location"];

interface HeaderProps extends React.HTMLProps<HTMLElement> {
  title: string;
  location: Location;
  visualSpec?: "default-page" | "home-page" | "reduced-height";
  themeSpec?: "dark" | "light";
  blogPostInfo?: React.ComponentProps<typeof BlogListItem>;
}
const Header = ({
  title,
  location: { pathname },
  visualSpec = "default-page",
  themeSpec = "dark",
  blogPostInfo,
  className, // Needs to be extracted so we can combine with our existing classes
  ...headerProps
}: HeaderProps): JSX.Element => {
  const rootPath = `${__PATH_PREFIX__}/`;

  const {
    homePageAnimationControls,
    blogsPageAnimationControls,
    blogPostPageAnimationControls,
  } = useAnimationContext();

  const { LTR, RTL, BTU, UTB } = AnimationDirection;
  const toHomeAnimationDirection = UTB;

  const toHomeClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    const exitVariant = getExitVariant(toHomeAnimationDirection);
    if (exitVariant) {
      if (pathname === "/blogs") {
        await blogsPageAnimationControls.start(exitVariant);
      } else if (pathname.startsWith("/blogs/")) {
        await blogPostPageAnimationControls.start(exitVariant);
      }
    }
    navigate("/", {
      state: {
        initialAnimateDir: toHomeAnimationDirection,
        blogPostInfo,
      },
    });
  };

  return (
    <header
      {...headerProps}
      className={cx(styles.header, className, {
        [styles.noPadding]: visualSpec === "home-page",
        [styles.centered]: visualSpec !== "home-page",
        [styles.light]: themeSpec === "light",
        [styles.reducedHeight]: visualSpec === "reduced-height",
      })}
    >
      {visualSpec !== "home-page" && (
        <h1 className={styles.title}>
          <Link
            to="/"
            state={{
              initialAnimateDir: toHomeAnimationDirection,
              blogPostInfo,
            }}
            onClick={toHomeClick}
          >
            {title}
          </Link>
        </h1>
      )}
      <nav className={styles.nav}>
        <Link
          className={cx(styles.navLink, {
            [styles.active]: pathname === rootPath,
          })}
          state={{
            initialAnimateDir: toHomeAnimationDirection,
            blogPostInfo,
          }}
          to="/"
          onClick={toHomeClick}
        >
          About
        </Link>
        <Link
          className={cx(styles.navLink, {
            [styles.active]: pathname.startsWith(`${rootPath}blogs`),
          })}
          state={{ initialAnimateDir: pathname === "/" ? BTU : RTL }}
          to="/blogs"
          onClick={async (event): Promise<void> => {
            event.preventDefault();
            if (pathname === "/") {
              const exitVariant = getExitVariant(BTU);
              if (exitVariant) {
                await homePageAnimationControls.start(exitVariant);
              }
            } else if (pathname.startsWith("/blogs/")) {
              const exitVariant = getExitVariant(LTR);
              if (exitVariant) {
                await blogPostPageAnimationControls.start(exitVariant);
              }
            }
            navigate("/blogs", {
              state: { initialAnimateDir: pathname === "/" ? BTU : LTR },
            });
          }}
        >
          Blog
        </Link>
      </nav>
    </header>
  );
};

export default Header;
