import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface SeoProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: any[];
}
const Seo = ({
  description = ``,
  lang = `en`,
  meta = [],
  title,
}: SeoProps): JSX.Element => {
  const {
    site: {
      siteMetadata: {
        description: siteMetadataDescription,
        title: siteMetadataTitle,
        socials: { twitter: twitterHandle },
      },
    },
  } = useStaticQuery(seoQuery);

  const metaDescription = description || siteMetadataDescription;
  const defaultTitle = siteMetadataTitle;
  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:site_name`,
      content: `Brandon Chong · Software Engineer`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: twitterHandle || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[...defaultMeta, ...meta]}
    />
  );
};

export default Seo;

const seoQuery = graphql`
  query SeoQuery {
    site {
      siteMetadata {
        title
        description
        socials {
          twitter
        }
      }
    }
  }
`;
